import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { useForm } from "react-hook-form"
import { connect } from "react-redux"
import * as actions from "@redux/actions"

import Layout from "@components/Layout"
import SEO from "@components/Seo"
import { H1 } from "@components/StyledElements"
import { Section, Label, Input, Button, Error, Success } from "@components/Form"
import Loader from "@components/Loader"

const ForgotPasswordPage = ({
  loading,
  ReduxForgotPassword,
  forgotPasswordError,
  forgotPasswordSuccess,
}) => {
  const data = useStaticQuery(graphql`
    query ForgotPasswordQuery {
      banner: allFile(filter: { name: { eq: "login_1440x400" } }) {
        edges {
          node {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1440) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
            id
          }
        }
      }
    }
  `)
  const [isDisabled, setIsDisabled] = useState(false)
  const [error, setError] = useState(null)
  const { register, handleSubmit, errors } = useForm()

  const onSubmit = async data => {
    try {
      setIsDisabled(true)
      const { email } = data
      ReduxForgotPassword({ email })
      setIsDisabled(false)
    } catch (error) {
      console.error(`Register Error: ${error}`)
      setError(
        `An error occurred and your information was not submitted. Please try again.`
      )
      setIsDisabled(false)
    }
  }

  const banner = data.banner.edges[0].node.childImageSharp.fluid
  return (
    <Layout fluid={banner} alt={"Neil Hill Coaching"} height={300}>
      <div className="container-fluid">
        <SEO title="Client Password Recovery" />

        <div className="row d-flex justify-content-center mt-5">
          <div className="col-md-8 mx-auto d-flex flex-column">
            <H1>Forgot Password</H1>
          </div>
        </div>

        <div className="row">
          <div className="col-md-8 mx-auto mt-2">
            <hr style={{ borderTop: "1px solid #d41f1f" }} />
          </div>
        </div>

        <div className="row d-flex justify-content-center mt-2">
          <form onSubmit={handleSubmit(onSubmit)} className="mb-5">
            <Section className="col-12">
              <Label>Email</Label>
              <Input
                type="text"
                placeholder="Email"
                name="email"
                ref={register({ required: "Email is required" })}
              />
              {errors.email && <Error message={errors.email.message} />}
            </Section>

            {loading ? (
              <div className="col-12 mt-2 text-center">
                <Loader />
              </div>
            ) : (
              <div className="col-12 text-center">
                <Button disabled={isDisabled} onClick={handleSubmit(onSubmit)}>
                  Reset Password
                </Button>
              </div>
            )}
            {forgotPasswordSuccess && (
              <div className="col-12 text-center mt-5">
                <Success message={forgotPasswordSuccess} />
              </div>
            )}
            {forgotPasswordError && (
              <div className="col-12 text-center mt-5">
                <Error message={forgotPasswordError} />
              </div>
            )}
            {error && (
              <div className="col-12 text-center mt-5">
                <Error message={error} />
              </div>
            )}
          </form>
        </div>
      </div>
    </Layout>
  )
}

const mapStateToProps = state => {
  return {
    authenticated: state.auth.authenticated,
    loading: state.auth.loading,
    forgotPasswordError: state.auth.forgotPasswordError,
    forgotPasswordSuccess: state.auth.forgotPasswordSuccess,
  }
}
export default connect(mapStateToProps, actions)(ForgotPasswordPage)
